const firebaseConfig = {
  projectId: "the-one-rentals-uaerc",
  appId: "1:583543004152:web:efea1df6ce89410297940d",
  databaseURL:
    "https://the-one-rentals-uaerc-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-one-rentals-uaerc.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyC94ZMKzGFExB4bQYwrFs6hHkI05O2c8k8",
  authDomain: "the-one-rentals-uaerc.firebaseapp.com",
  messagingSenderId: "583543004152",
  measurementId: "G-12P9SVJVRM",
};

export default firebaseConfig;
